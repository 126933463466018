import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const LogoImageWrapper = styled.span`
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  vertical-align: middle;
`;

export const StepsWrapper = styled.div<{ theme: ThemeType }>`
  display: block;
  height: ${({ theme }) => theme.spacing.m};
  width: ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.tokenColors.cyan_900};
  margin: ${({ theme }) =>
    `-${theme.spacing.xxs} auto ${theme.spacing.xxs} auto`};
  transform: rotate(270deg);
  svg {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;
export const MarkerWrapper = styled.div<{ theme: ThemeType }>`
  display: block;
  margin: ${({ theme }) =>
    `-${theme.spacing.xxs} auto ${theme.spacing.xxs} auto`};
  height: ${({ theme }) => theme.spacing.m};
  width: ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.tokenColors.cyan_900};
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const RowWrap = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
