import { useRef, type ReactNode } from "react";
import { Button, Box, Text } from "@cruk/cruk-react-components";

import {
  checkValidationForErrorMessage,
  checkIfFileResizable,
  getOrientationFromExifData,
} from "@fwa/src/utils/imageUploadUtils";
import { DragWrapper, DottedOutline } from "./styles";

// JPEG quality
const QUALITY = 0.75;
interface UploadData {
  error?: string;
  file?: Blob;
  image?: {
    localUrl: string;
  };
}

const fromImageElementToCanvasElement = (
  image: HTMLImageElement,
  resizeMaxDimension: number,
  orientation: number,
): HTMLCanvasElement => {
  const canvas = document.createElement("canvas");
  let { width, height } = image;
  // Client side resize to improve upload speed from mobile phones.
  if (width > resizeMaxDimension || height > resizeMaxDimension) {
    height *= 0.5;
    width *= 0.5;
  }
  const ctx = canvas.getContext("2d");
  // Remove and apply exif orientation.
  if (ctx) {
    if (orientation > 4 && orientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }
    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }
    ctx.drawImage(image, 0, 0, width, height);
  }
  return canvas;
};

export const ImageUploader = ({
  id,
  onImageUpload,
  acceptFileTypes = ["jpeg", "gif", "png"],
  resizeFileTypes = ["jpeg"],
  maxSizeMB = 4,
  resizeMaxSizeMB = 8,
  resizeMaxDimension = 3000,
  renderDragArea,
}: {
  id?: string;
  onImageUpload: (res: UploadData) => void;
  acceptFileTypes?: string[];
  resizeFileTypes?: string[];
  maxSizeMB?: number;
  resizeMaxSizeMB?: number;
  resizeMaxDimension?: number;
  renderDragArea?: ReactNode;
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFile = (file: File | null) => {
    // handle null
    if (file === null) return;
    const removeFile = () => {
      if (fileInput.current) fileInput.current.value = "";
    };

    // Validation.
    const errorMessage = checkValidationForErrorMessage(
      file,
      acceptFileTypes,
      resizeFileTypes,
      resizeMaxSizeMB,
      maxSizeMB,
    );
    if (errorMessage) {
      onImageUpload({
        error: errorMessage,
      });
      removeFile();
      return;
    }

    // Handle image load
    const isFileResizable = checkIfFileResizable(file, resizeFileTypes);
    const readerDataURl = new FileReader();
    const readerArrayBuffer = new FileReader();

    if (isFileResizable) {
      // first we need to load file as arraybuffer check image orientation in exif header
      readerArrayBuffer.onloadend = () => {
        if (!readerArrayBuffer.result) return;
        const arrayBuffer = readerArrayBuffer.result as ArrayBuffer;
        const dataView = new DataView(arrayBuffer);
        const orientation = getOrientationFromExifData(dataView);
        // next we want to re-load the file as url encoded data
        // we need this to put it in an image element
        // which we will use to load as an image into a canvas element for resizing
        readerDataURl.onloadend = () => {
          if (!readerDataURl.result) return;
          const image = new Image();
          image.onload = () => {
            // take the image component get the canvase to draw the same image
            // resize if need and use the orientation value to render image correct way up
            const canvas: HTMLCanvasElement = fromImageElementToCanvasElement(
              image,
              resizeMaxDimension,
              orientation,
            );
            const resizedAsUrl = canvas.toDataURL(file.type, QUALITY);
            // now get new resized file out of the canvas and use that as new current image state
            canvas.toBlob(
              (blob: Blob | null) => {
                if (!blob) return;
                onImageUpload({
                  image: { localUrl: resizedAsUrl },
                  file: blob,
                });
              },
              file.type,
              QUALITY,
            );
          };
          image.src = readerDataURl.result as string;
        };
        readerDataURl.readAsDataURL(file);
      };
      readerArrayBuffer.readAsArrayBuffer(file);
    } else {
      // first load the file as url encoded data
      readerDataURl.onloadend = () => {
        if (!readerDataURl.result) return;
        onImageUpload({
          image: { localUrl: readerDataURl.result as string },
          file,
        });
      };
      readerDataURl.readAsDataURL(file);
    }
    removeFile();
  };

  return (
    <DragWrapper
      role="button"
      tabIndex={0}
      style={{ height: "100%", cursor: "pointer" }}
      onDrop={(e) => {
        e.preventDefault();
        handleFile(e.dataTransfer.files[0]);
      }}
      onDragOver={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onClick={() => fileInput.current && fileInput.current.click()}
      onKeyDown={(e) => {
        if (e.key === "Enter" && fileInput.current) fileInput.current.click();
      }}
      data-component="image-uploader"
    >
      {renderDragArea || (
        <DottedOutline>
          <Box marginVertical="m">
            <Text textSize="l" textAlign="center">
              Drop file here to upload
            </Text>
            <Text textAlign="center">or</Text>
            <Button
              appearance="primary"
              as="span"
              onClick={(e) => {
                e.preventDefault();
              }}
              data-cta-type="image-choose"
            >
              Browse
            </Button>
          </Box>
        </DottedOutline>
      )}
      <input
        type="file"
        id={id || "imageFile"}
        style={{ display: "none" }}
        onChange={(e) => e.target.files && handleFile(e.target.files[0])}
        accept={acceptFileTypes.map((item) => `image/${item}`).join(",")}
        ref={fileInput}
      />
    </DragWrapper>
  );
};

export default ImageUploader;
