import styled from "styled-components";

export const DragWrapper = styled.span`
  cursor: pointer;
  text-align: center;
`;
export const DottedOutline = styled.div`
  background-color: #eee;
  border: 2px dashed #ccc;
  border-radius: 4px;
`;
