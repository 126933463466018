import React, { useEffect, useState } from "react";
import NextLink from "next/link";
import {
  Box,
  Button,
  IconFa,
  Badge,
  ErrorText,
  crukTheme,
  Link as A,
} from "@cruk/cruk-react-components";
import { useRouter } from "next/compat/router";
import {
  faFlag,
  faSterlingSign,
  faHeartPulse,
  faComment,
  faEdit,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { fetcher } from "@fwa/src/services/apiClient";
import { fwsUrlFeedItemDelete } from "@fwa/src/services/feedItemService";
import { userNameForPage } from "@fwa/src/utils/pageUtils";
import { queryAsString } from "@fwa/src/utils/urlUtils";
import { imagePathFromImageType } from "@fwa/src/services/imageService";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";

import { FormActions } from "@fwa/src/components/FormActions";
import { FeedItemDonationForm } from "@fwa/src/components/FeedItemDonationForm";
import { FeedItemPostForm } from "@fwa/src/components/FeedItemPostForm";
import { FeedItemStrava } from "@fwa/src/components/FeedItemStrava";
import { FeedItemFitbit } from "@fwa/src/components/FeedItemFitbit";
import { FeedItemMilestone } from "@fwa/src/components/FeedItemMilestone";
import { Share } from "@fwa/src/components/Share";
import { Highlight } from "@fwa/src/components/Highlight";

import {
  FeedItemWrapper,
  ActionButtonWrapper,
  FeedItemFooter,
  IconBadgeWrapper,
} from "@fwa/src/components/FeedItem/styles";

import {
  type FeedItemType,
  type FeedItemStatusType,
  type FeedItemMilestoneType,
  type FeedItemDonationRealType,
  type FeedItemDonationFakeType,
  type FeedItemDonationFacebookType,
  type FeedItemPostType,
  type FeedItemActivityStravaType,
  type FeedItemEntityTypesType,
  type FeedItemActivityFitbitType,
} from "@fwa/src/types";

const crukCLogo = "/assets/images/logos/cruk_c_128.png";

const CommentBadge = () => (
  <IconBadgeWrapper>
    <Badge backgroundColor="tertiary" size="s">
      <IconFa faIcon={faComment} size="s" color="white" />
    </Badge>
  </IconBadgeWrapper>
);
const FlagBadge = () => (
  <IconBadgeWrapper>
    <Badge backgroundColor="secondary" size="s">
      <IconFa faIcon={faFlag} size="s" color="white" />
    </Badge>
  </IconBadgeWrapper>
);
const ActivityBadge = () => (
  <IconBadgeWrapper>
    <Badge
      backgroundColor={`${crukTheme.tokenColors.cyan_700 || "#000000"}`}
      size="s"
    >
      <IconFa faIcon={faHeartPulse} size="s" color="white" />
    </Badge>
  </IconBadgeWrapper>
);
const PoundBadge = () => (
  <IconBadgeWrapper>
    <Badge backgroundColor="primary" size="s">
      <IconFa faIcon={faSterlingSign} size="s" />
    </Badge>
  </IconBadgeWrapper>
);

type FeedItemProps = {
  feedItem: FeedItemType;
  handleDelete: (updatedItem: FeedItemType) => void;
  handleEdit: (updatedItem: FeedItemType) => void;
  pageUrl: string | null;
  pageId: string;
  isStravaMetric: boolean;
  fitbitDailyTargetSteps: number;
  showParentPageLink?: boolean;
  isDonationConfirmation?: boolean;
};

export const FeedItem = ({
  feedItem,
  handleDelete,
  handleEdit,
  pageUrl,
  pageId,
  isStravaMetric,
  fitbitDailyTargetSteps,
  showParentPageLink = false,
  isDonationConfirmation = false,
}: FeedItemProps) => {
  const { trackError, sendTrackingEvent } = useTrackingContext();
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<FeedItemStatusType>("idle");
  const [secret, setSecret] = useState("");
  const router = useRouter();

  const userName = userNameForPage(feedItem?.page);
  const displayNameOrUserName = feedItem?.page?.owner?.displayName
    ? feedItem.page.owner.displayName
    : userName;

  const [fundraiserState] = useFundraiserContext();
  const { fundraiser } = fundraiserState;

  const isOwner: boolean =
    isDonationConfirmation || // if you see the donation confirmation assume that you are the donor can edit donation feed item
    (fundraiser && // logged in fundraiser is the page owner where feed item belongs
      fundraiser?.uniqueId === feedItem.page?.fundraiser?.uniqueId) ||
    false;

  const isEditable: boolean =
    (feedItem.entityType === "DonationReal" && !!secret) || // Donor editing their donation from email link
    (isOwner &&
      (feedItem.entityType === "Post" ||
        (feedItem.entityType === "DonationReal" &&
          "originatorPaymentId" in feedItem) || // Donor is editing their donation
        (feedItem.entityType === "DonationFake" &&
          "online" in feedItem &&
          !feedItem.online))) || // Ignore Facebook donations possibly
    false;

  const isDeletable =
    isOwner &&
    (feedItem.entityType === "Post" ||
      feedItem.entityType === "Milestone" ||
      feedItem.entityType === "ActivityStrava" ||
      (feedItem.entityType === "DonationFake" &&
        "online" in feedItem &&
        !feedItem.online));

  // Avatar URL start ///////////////////////

  // Order of preference
  // 1) special kickstarter fake donations and milestones have hard coded image urls because of an issue with uploading them in legacy admin.
  // 2) special fake donations and milstones sometimes have a profileImage URLs defined in the feedItem data.
  // 3) Real donations from the owner might be missing a profile image in the donation so lets use their account profile image url as the feeditem avatar url
  // 4) Real donations from everyone else has avatarUrl as 'undefined' letting the avatar image component derrive a default letter image from avatar name

  const pageFundraiserProfileImage: string | undefined =
    !feedItem?.page?.owner && feedItem?.page?.fundraiser?.profileImage
      ? feedItem.page.fundraiser.profileImage.entityType === "ImageFacebook"
        ? `${feedItem.page.fundraiser.profileImage.url}?width=300&height=300`
        : imagePathFromImageType(feedItem.page.fundraiser?.profileImage)
      : undefined;

  // now this is grim donation.profileImage is a string and milestone.profileImage is an ImageType
  const feedItemAsDonation:
    | FeedItemDonationRealType
    | FeedItemDonationFakeType
    | FeedItemDonationFacebookType
    | undefined =
    feedItem.entityType === "DonationReal" ||
    feedItem.entityType === "DonationFake"
      ? (feedItem as FeedItemDonationRealType | FeedItemDonationFakeType)
      : undefined;
  const feedItemAsDonationFake: FeedItemDonationFakeType | undefined =
    feedItem.entityType === "DonationFake"
      ? (feedItem as FeedItemDonationFakeType)
      : undefined;
  const feedItemAsMilestone: FeedItemMilestoneType | undefined =
    feedItem.entityType === "Milestone"
      ? (feedItem as FeedItemMilestoneType)
      : undefined;

  // special cases where FWA forces the avatar image
  const kickStartAvatarImages = {
    Tesco: "https://fundraise.cancerresearchuk.org/images/tescosquare.png",
    "Standard Life":
      "https://fundraise.cancerresearchuk.org/images/standardlifeletterssquare.jpg",
  };
  const kickStarterDonationName:
    | keyof typeof kickStartAvatarImages
    | undefined =
    (feedItemAsDonationFake?.donationName?.trim() || "") in
    kickStartAvatarImages
      ? (feedItemAsDonationFake?.donationName?.trim() as keyof typeof kickStartAvatarImages)
      : undefined;

  const kickStartAvatarUrl: string | undefined = kickStarterDonationName
    ? kickStartAvatarImages[kickStarterDonationName]
    : undefined;

  const milestoneAvatarUrl: string | undefined = feedItemAsMilestone
    ? crukCLogo
    : undefined;

  const forcedAvatarUrl = kickStartAvatarUrl || milestoneAvatarUrl;

  const feedItemProfileImageUrl: string | undefined =
    forcedAvatarUrl ||
    feedItemAsDonation?.profileImage ||
    feedItemAsMilestone?.profileImage?.url;

  const isDonerAndOwner =
    "donationName" in feedItem &&
    feedItemAsDonation?.donationName === displayNameOrUserName;
  const donationProfileImageUrl = isDonerAndOwner
    ? pageFundraiserProfileImage
    : feedItemProfileImageUrl; // undefined means it uses the default images with letters

  const avatarUrl: string | undefined = feedItemAsDonation
    ? donationProfileImageUrl
    : feedItemProfileImageUrl || pageFundraiserProfileImage;

  // Avatar URL end ///////////////////////

  const parentPageLink =
    showParentPageLink && feedItem.page.entityType === "FundraisingPage" ? (
      <NextLink
        href={`/page${feedItem.page.url.slice(
          feedItem.page.url.lastIndexOf("/"),
        )}?feed=${feedItem.uniqueId}`}
        data-cta-type="link-strava-check-account"
      >
        <A as="span">{feedItem.page.title}</A>
      </NextLink>
    ) : undefined;

  const handleCancelClick = () => {
    setStatus("idle");
  };

  const handleDeleteClick = () => {
    setStatus("deleting");
    setError(null);

    const url = fwsUrlFeedItemDelete(feedItem);
    fetcher(url, {
      method: "DELETE",
    })
      .then((res) => res as Response)
      .then((res: Response) => {
        if (res && !res.ok) {
          setError(new Error("Unable to delete"));
          return undefined;
        }
        sendTrackingEvent({
          event: "feeditem_delete",
          entityType: feedItem.entityType,
        });
        if (handleDelete) {
          handleDelete(feedItem);
        }
        return undefined;
      })
      .catch((err: Error) => {
        trackError(err, { component: "FeedItem" });
        setStatus("idle");
        setError(new Error("Unable to delete"));
      });
  };

  const handleEditData = (data: Partial<FeedItemType>) => {
    setStatus("idle");
    handleEdit({ ...feedItem, ...data });
    sendTrackingEvent({
      event: "feeditem_edit",
      entityType: feedItem.entityType,
    });
  };

  // Donor can edit donation message from email link with secret is in query string
  useEffect(() => {
    if (router?.query.secret) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { secret: secretInQuery, slug, ...rest } = router.query;
      setSecret(queryAsString(secretInQuery));
      router
        .push(
          {
            pathname: router.asPath.split("?")[0],
            query: rest,
          },
          undefined,
          {
            shallow: true,
          },
        )
        .catch((err) => {
          trackError(err as Error, { component: "FeedItem" });
        });
    }
  }, [router, trackError]);

  const renderItem = (pageUrl: string) => {
    switch (feedItem.entityType) {
      case "ActivityStrava":
        return (
          <FeedItemStrava
            feedItem={feedItem as FeedItemActivityStravaType}
            avatarUrl={avatarUrl}
            displayName={displayNameOrUserName}
            isMetric={isStravaMetric}
          />
        );
      case "ActivityFitbit":
        return (
          <FeedItemFitbit
            feedItem={feedItem as FeedItemActivityFitbitType}
            avatarUrl={avatarUrl}
            displayName={displayNameOrUserName}
            fitbitDailyTargetSteps={fitbitDailyTargetSteps}
          />
        );
      case "Post":
        return (
          <Box>
            <FeedItemPostForm
              feedItem={feedItem as FeedItemPostType}
              avatarUrl={avatarUrl}
              displayName={displayNameOrUserName}
              status={status}
              handleCancelClick={handleCancelClick}
              handleEditData={handleEditData}
              parentPageLink={parentPageLink}
            />
          </Box>
        );
      case "Milestone":
        return (
          <div>
            <FeedItemMilestone
              feedItem={feedItem as FeedItemMilestoneType}
              avatarUrl={avatarUrl}
            />
          </div>
        );
      case "DonationReal":
        return (
          <FeedItemDonationForm
            feedItem={feedItem as FeedItemDonationRealType}
            avatarUrl={avatarUrl}
            handleCancelClick={handleCancelClick}
            handleEditData={handleEditData}
            status={status}
            parentPageLink={parentPageLink}
            pageUrl={pageUrl}
            pageId={pageId}
            secret={secret}
          />
        );
      case "DonationFacebook":
        return (
          <FeedItemDonationForm
            feedItem={feedItem as FeedItemDonationFacebookType}
            avatarUrl={avatarUrl}
            handleCancelClick={handleCancelClick}
            handleEditData={handleEditData}
            status={status}
            parentPageLink={parentPageLink}
            pageUrl={pageUrl}
            pageId={pageId}
          />
        );
      case "DonationFake":
        return (
          <FeedItemDonationForm
            feedItem={feedItem as FeedItemDonationFakeType}
            avatarUrl={avatarUrl}
            handleCancelClick={handleCancelClick}
            handleEditData={handleEditData}
            status={status}
            parentPageLink={parentPageLink}
            pageUrl={pageUrl}
            pageId={pageId}
          />
        );
      default:
        return null;
    }
  };

  const isHighlighted = status === "editing" || status === "deleting";

  const feedItemBadges: Record<FeedItemEntityTypesType, JSX.Element> = {
    Post: <CommentBadge />,
    Milestone: <FlagBadge />,
    ActivityStrava: <ActivityBadge />,
    ActivityFitbit: <ActivityBadge />,
    DonationReal: <PoundBadge />,
    DonationFake: <PoundBadge />,
    DonationFacebook: <PoundBadge />,
  };

  return (
    <Highlight isHighlighted={isHighlighted}>
      <FeedItemWrapper className="well feed-item__content">
        {feedItemBadges[feedItem.entityType]}

        {pageUrl ? renderItem(pageUrl) : null}

        {error ? <ErrorText>{error.message}</ErrorText> : null}

        {status === "deleting" && (
          <FormActions
            submitLabel="Delete"
            onCancel={handleCancelClick}
            onSubmit={handleDeleteClick}
            submitButtonType="button"
          />
        )}

        {status === "idle" && (
          <FeedItemFooter data-component="feed-item-footer">
            <ActionButtonWrapper>
              {isDeletable ? (
                <Button
                  appearance="tertiary"
                  className="delete-link"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setStatus("deleting");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setStatus("deleting");
                    }
                  }}
                  data-cta-type="delete-feed-item"
                >
                  <IconFa faIcon={faTrashCan} />
                  Delete
                </Button>
              ) : null}

              {isEditable ? (
                <Button
                  appearance="tertiary"
                  className="edit-link"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setStatus("editing");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setStatus("editing");
                    }
                  }}
                  data-cta-type="edit-feed-item"
                >
                  <IconFa faIcon={faEdit} />
                  Edit
                </Button>
              ) : null}

              <Share
                isOwner={isEditable}
                page={feedItem.page}
                feedItem={feedItem}
                ctaLocation="feedItem"
              >
                <Button
                  type="button"
                  appearance="tertiary"
                  data-cta-type="open-share"
                >
                  <IconFa faIcon={faShareAlt} />
                  Share
                </Button>
              </Share>
            </ActionButtonWrapper>
          </FeedItemFooter>
        )}
      </FeedItemWrapper>
    </Highlight>
  );
};

export default FeedItem;
