import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const StravaTable = styled.div`
  max-width: 400px;
`;

export const LogoImageWrapper = styled.div<{ theme: ThemeType }>`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.xxs};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;
