import styled from "styled-components";
import { type ThemeType } from "@cruk/cruk-react-components";

export const SpanVerticalSpacer = styled.span<{ theme: ThemeType }>`
  margin-right: ${({ theme }) => theme.spacing.xxs};
`;

export const FacebookInput = styled.input<{ theme: ThemeType }>`
  margin: 0px;
  height: ${({ theme }) => theme.typography.lineHeight};
  width: ${({ theme }) => theme.typography.lineHeight};
  vertical-align: middle;
`;

export const ImageWrapper = styled.div`
  max-width: 200px;
  margin: auto;
`;

export const UploadedImageWrapper = styled.div`
  max-width: 70vh;
  margin: auto;
`;
