import { imagePathFromImageType } from "@fwa/src/services/imageService";

import {
  GalleryImageOuter,
  GalleryImageInner,
  GalleryImage,
} from "@fwa/src/components/GalleryThumbnail/styles";

import { type ImageType, type RotateType } from "@fwa/src/types";

interface UploadedImage {
  url: string;
  title?: string;
  height?: string;
  width?: string;
}

type Props = {
  image: ImageType | UploadedImage;
  rotation?: RotateType;
  hasBorder?: boolean;
};

export const GalleryThumbnail = ({
  image,
  rotation,
  hasBorder = true,
}: Props) => (
  <GalleryImageOuter $hasBorder={hasBorder}>
    <GalleryImageInner>
      {image && (
        <GalleryImage
          alt={image?.title || ""}
          src={
            "filePath" in image && image.filePath
              ? imagePathFromImageType(image)
              : image.url
          }
          height={image?.height}
          width={image?.width}
          $rotation={rotation}
        />
      )}
    </GalleryImageInner>
  </GalleryImageOuter>
);

export default GalleryThumbnail;
