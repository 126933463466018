import { type ThemeType } from "@cruk/cruk-react-components";
import { type RotateType } from "@fwa/src/types";
import styled from "styled-components";

export const GalleryImageWrapper = styled.li`
  list-style: none;
`;

export const GalleryImageOuter = styled.div<{
  theme: ThemeType;
  $hasBorder: boolean;
}>`
  padding: ${({ theme }) => theme.spacing.xxs};
  background-color: ${({ theme }) => theme.colors.backgroundMid};
  box-shadow: ${({ theme }) => theme.shadows.s};
`;

// Keeps thumbnail square
export const GalleryImageInner = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
`;

export const GalleryImage = styled.img<{
  $rotation?: RotateType;
}>`
  position: absolute;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 1s ease;
  transform: ${({ $rotation }) => `rotate(${$rotation || 0}deg)`};
`;
