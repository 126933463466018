import styled from "styled-components";
import {
  TextAreaField,
  type ThemeType,
  type FontSizeType,
} from "@cruk/cruk-react-components";

export const TextAreaStyled = styled(TextAreaField)<{
  theme: ThemeType;
  $textSize: FontSizeType;
  $lineCount: number;
}>`
  font-size: ${({ theme, $textSize }) =>
    !!$textSize && !!theme?.fontSizes[$textSize]
      ? theme?.fontSizes[$textSize]
      : theme.fontSizes.m};
  height: ${({ lineCount }) => `calc(1.5em * ${lineCount})`};
`;

export default TextAreaStyled;
