import styled from "styled-components";
import {
  TextField,
  type ThemeType,
  type FontSizeType,
} from "@cruk/cruk-react-components";

export const TextFieldStyled = styled(TextField)<{
  theme: ThemeType;
  $textSize: FontSizeType;
}>`
  font-size: ${({ theme, $textSize }) =>
    !!$textSize && !!theme?.fontSizes[$textSize]
      ? theme?.fontSizes[$textSize]
      : theme.fontSizes.m};
`;

export default TextFieldStyled;
