import { UserBlock, Text, IconFa, Box } from "@cruk/cruk-react-components";
import { formatDistanceToNow } from "date-fns";
import { faClock } from "@fortawesome/free-regular-svg-icons";

import { imagePathFromImageType } from "@fwa/src/services/imageService";

import {
  EverythingWrapper,
  BackgroundImageWrapper,
  TextContentWrapper,
  BackgroundImage,
} from "@fwa/src/components/FeedItemMilestone/styles";

import { type FeedItemMilestoneType } from "@fwa/src/types";

type Props = {
  feedItem: FeedItemMilestoneType;
  avatarUrl?: string;
};

export const FeedItemMilestone = ({
  feedItem: { created, body, title, subtitle, backgroundImage },
  avatarUrl,
}: Props) => (
  <div data-component="feed-item-milestone">
    <Box marginBottom="xs">
      <UserBlock
        name="Cancer Research UK"
        avatarUrl={avatarUrl}
        size="m"
        extra={
          <Text as="span" textColor="currentColor">
            <IconFa faIcon={faClock} />{" "}
            {created
              ? `${formatDistanceToNow(new Date(created), {
                  addSuffix: true,
                  includeSeconds: true,
                })}`
              : "Right Now"}
          </Text>
        }
      />
    </Box>
    <Box>
      <Box>
        <EverythingWrapper>
          {backgroundImage && (
            <BackgroundImageWrapper>
              <BackgroundImage
                src={
                  backgroundImage ? imagePathFromImageType(backgroundImage) : ""
                }
                alt=""
              />
            </BackgroundImageWrapper>
          )}
          <TextContentWrapper>
            {title && (
              <Text
                as="h3"
                textSize="xxl"
                textColor="textLight"
                textAlign="center"
              >
                {title}
              </Text>
            )}
            {subtitle && (
              <Text
                as="h4"
                textSize="m"
                textColor="textLight"
                textAlign="center"
              >
                {subtitle}
              </Text>
            )}
          </TextContentWrapper>
        </EverythingWrapper>
      </Box>
      {body ? <Text>{body}</Text> : null}
    </Box>
  </div>
);

export default FeedItemMilestone;
