import React, {
  useState,
  useRef,
  type MouseEvent,
  type KeyboardEvent,
  type ReactNode,
} from "react";
import { Text } from "@cruk/cruk-react-components";
import { useLayoutEffectBrowser } from "@fwa/src/hooks/useLayoutEffectBrowser";

type Props = {
  children: ReactNode | string;
};
export const ReadMore = ({ children }: Props) => {
  const [readMore, setReadMore] = useState(false);
  const [messageBodyHeight, setMessageBodyHeight] = useState<number | null>(
    null,
  );
  const [messageWrapperHeight, setMessageWrapperHeight] = useState<
    number | null
  >(null);

  const messageBody = useRef<HTMLDivElement>(null);
  const messageWrapper = useRef<HTMLDivElement>(null);

  useLayoutEffectBrowser(() => {
    if (!messageBody?.current?.clientHeight) return;
    const calculatedessageBodyHeight: number = messageBody.current.clientHeight;
    setMessageBodyHeight(messageBodyHeight);
    if (!messageWrapper?.current?.clientHeight) return;
    setReadMore(
      messageWrapper?.current?.clientHeight < calculatedessageBodyHeight,
    );
  }, [messageBody, messageWrapper]);

  const onReadMoreClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setMessageWrapperHeight(messageBodyHeight);
    setReadMore(false);
  };
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setMessageWrapperHeight(messageBodyHeight);
    setReadMore(false);
  };

  return (
    <div
      ref={messageWrapper}
      style={
        messageWrapperHeight
          ? {
              maxHeight: `${messageWrapperHeight * 2}px`,
            }
          : undefined
      }
    >
      <div ref={messageBody}>
        <Text>{children}</Text>
      </div>
      {readMore && (
        <div
          onClick={onReadMoreClick}
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") onKeyDown(e);
          }}
          role="button"
          tabIndex={0}
          className="more"
        >
          Read more
        </div>
      )}
    </div>
  );
};

export default ReadMore;
