import { FWS_BASE_URL } from "@fwa/src/services/apiClient";

export const urlNewDonor = `${FWS_BASE_URL}/donors`;

export const fwsUrlNewDonation = ({
  pageId,
  donorId,
}: {
  pageId: string;
  donorId: string;
}) => `${FWS_BASE_URL}/pages/${pageId}/donors/${donorId}/donations`;

export const fwsUrlDonation = ({ donationId }: { donationId: string }) =>
  `${FWS_BASE_URL}/donations/${donationId}`;

export const fwsUrlNewFakeDonation = ({ pageId }: { pageId: string }) =>
  `${FWS_BASE_URL}/pages/${pageId}/donations`;
