import styled from "styled-components";

export const EverythingWrapper = styled.div`
  position: relative;
`;

export const BackgroundImageWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  height: auto;
  margin: auto;
  overflow: hidden;
`;

export const BackgroundImage = styled.img`
  height: 100%;
  width: auto;
  margin: auto;
  display: block;
`;

export const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  * {
    max-width: 250px;
  }
`;
