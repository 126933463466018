import {
  UserBlock,
  Text,
  Heading,
  Link,
  Box,
  IconFa,
} from "@cruk/cruk-react-components";
import { formatDistanceToNow } from "date-fns";
import { faClock } from "@fortawesome/free-regular-svg-icons";

import { imagePathFromImageType } from "@fwa/src/services/imageService";
import { secondsToDurationString } from "@fwa/src/utils/timeUtils";

import {
  StravaTable,
  LogoImageWrapper,
} from "@fwa/src/components/FeedItemStrava/styles";
import { Column, Row, RowStretch } from "@fwa/src/components/styles";

import { type FeedItemActivityStravaType } from "@fwa/src/types";

const stravaLogo = "/assets/images/logos/strava-rounded-128.png";

type Props = {
  feedItem: FeedItemActivityStravaType;
  avatarUrl: string | undefined;
  displayName: string;
  isMetric: boolean;
};

export const FeedItemStrava = ({
  feedItem,
  avatarUrl,
  displayName,
  isMetric,
}: Props) => (
  <div data-component="feed-item-strava">
    <Box marginBottom="xs">
      <UserBlock
        name={displayName}
        avatarUrl={avatarUrl}
        extra={
          <Text as="span" textColor="currentColor">
            <IconFa faIcon={faClock} />
            {formatDistanceToNow(new Date(feedItem.created), {
              addSuffix: true,
              includeSeconds: true,
            })}
          </Text>
        }
        size="m"
      />
    </Box>
    <Heading h3 textSize="l">
      {feedItem.name}
    </Heading>
    <Box marginBottom="s">
      <StravaTable>
        <RowStretch>
          <Column>
            <Text marginBottom="xxs">Activity</Text>
            <Text>{feedItem.stravaActivityType}</Text>
          </Column>
          <Column>
            <Text marginBottom="xxs">Distance</Text>
            {isMetric ? (
              <Text>{((feedItem.distance || 0) / 1000).toFixed(2)}km</Text>
            ) : (
              <Text>{((feedItem.distance || 0) / 1609.34).toFixed(2)}mi</Text>
            )}
          </Column>
          <Column>
            <Text marginBottom="xxs">Time</Text>
            {feedItem.time ? (
              <Text> {secondsToDurationString(feedItem.time)}</Text>
            ) : null}
          </Column>
        </RowStretch>
      </StravaTable>
    </Box>
    {feedItem.map && (
      <Box marginBottom="s">
        <img
          className="feed-item__strava-map"
          src={
            feedItem.map.image ? imagePathFromImageType(feedItem.map.image) : ""
          }
          alt=""
        />
      </Box>
    )}

    <Row>
      <LogoImageWrapper>
        <img src={stravaLogo} alt="Strava activity" className="profile-image" />
      </LogoImageWrapper>
      <Link
        appearance="secondary"
        href={`https://www.strava.com/activities/${feedItem.stravaId || ""}`}
      >
        View on Strava
      </Link>
    </Row>
  </div>
);

export default FeedItemStrava;
