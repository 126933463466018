export const checkIfFileResizable = (file: Blob, resizeFileTypes: string[]) => {
  return resizeFileTypes.includes(file.type.replace("image/", ""));
};

export const checkValidationForErrorMessage = (
  file: Blob,
  acceptFileTypes: string[],
  resizeFileTypes: string[],
  resizeMaxSizeMB: number,
  maxSizeMB: number,
): string | undefined => {
  const isfileTypeAllowed = acceptFileTypes.includes(
    file.type.replace("image/", ""),
  );
  const isFileResizable = checkIfFileResizable(file, resizeFileTypes);
  const isFileResizeTooBig = file.size / 1024 / 1024 > resizeMaxSizeMB;
  const isFileTooBig = file.size / 1024 / 1024 > maxSizeMB;
  const nonResizableTypes = acceptFileTypes.filter(
    (item) => !resizeFileTypes.includes(item),
  );

  if (!isfileTypeAllowed) {
    return `Image should be a ${acceptFileTypes.join(" ").toUpperCase()}`;
  }
  if (isFileResizable && isFileResizeTooBig) {
    return `${resizeFileTypes
      .join(" ")
      .toUpperCase()} images should be less than ${resizeMaxSizeMB}MB`;
  }
  if (!isFileResizable && isFileTooBig) {
    return `${nonResizableTypes
      .join(" ")
      .toUpperCase()} images should be less than ${maxSizeMB}MB`;
  }
  return undefined;
};

export const getOrientationFromExifData = (dataView: DataView): number => {
  if (dataView.getUint16(0, false) !== 0xffd8) {
    return -2;
  }
  const length = dataView.byteLength;
  let offset = 2;
  while (offset < length) {
    if (dataView.getUint16(offset + 2, false) <= 8) return -1;
    const marker = dataView.getUint16(offset, false);
    offset += 2;
    // 0xFFE1 for EXIF data
    if (marker === 0xffe1) {
      if (dataView.getUint32((offset += 2), false) != 0x45786966) {
        return -1;
      }
      // 0x4d4d big endian, 0x4949 little endian
      const little = dataView.getUint16((offset += 6), false) === 0x4949;
      offset += dataView.getUint32(offset + 4, little);
      const tags = dataView.getUint16(offset, little);
      offset += 2;
      for (let i = 0; i < tags; i++) {
        if (dataView.getUint16(offset + i * 12, little) === 0x0112) {
          return dataView.getUint16(offset + i * 12 + 8, little);
        }
      }
    } else if ((marker & 0xff00) != 0xff00) {
      break;
    } else {
      offset += dataView.getUint16(offset, false);
    }
  }
  return -1;
};
