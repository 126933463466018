import styled from "styled-components";
import { type ThemeType } from "@cruk/cruk-react-components";

export const FeedItemWrapper = styled.div<{ theme: ThemeType }>`
  position: relative;
  min-height: ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => theme.spacing.s};
  border: 1px solid ${({ theme }) => theme.tokenColors.grey_300};
`;

export const ActionButtonWrapper = styled.div<{ theme: ThemeType }>`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > * {
    margin: ${({ theme }) => `0 0 -${theme.spacing.xs} ${theme.spacing.xs}`};
    height: auto;

    &:first-child {
      margin: ${({ theme }) => `0 0 -${theme.spacing.xs} 0`};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    justify-content: center;
  }
`;

export const FeedItemFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const IconBadgeWrapper = styled.span<{ theme: ThemeType }>`
  position: absolute;
  left: -${({ theme }) => theme.spacing.xs};
  top: -${({ theme }) => theme.spacing.xs};
`;
