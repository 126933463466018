import { FWS_BASE_URL } from "@fwa/src/services/apiClient";
import {
  type FeedItemEntityTypesType,
  type FeedItemTabType,
  type FeedItemType,
} from "@fwa/src/types";

// convert feed item entity types to feed item types used in the API
// often lower case and pluralized
export const feedItemTypeFromEntityType = (
  entityType: FeedItemEntityTypesType,
): FeedItemTabType => {
  switch (entityType) {
    case "ActivityStrava":
      return "activitys";
    case "Post":
      return "posts";
    case "Milestone":
      return "milestones";
    default:
      return "donations";
  }
};

// activities only exist on fundraising pages, all the other feeditem types exist on generic pages
// which includes teams and super teams, this is a bit of a gotya because this util is used
// in feeditem lists for teams and super teams.
export const fwsUrlFeedItems = ({
  pageId,
  currentPage,
  itemsPerPage,
  type = "feeditems",
}: {
  pageId: string;
  currentPage: number;
  itemsPerPage: number;
  type?: FeedItemTabType;
}): string =>
  `${FWS_BASE_URL}/${
    type === "activitys" ? "fundraisingpages" : "pages"
  }/${pageId}/${type}?offset=${
    Math.max(currentPage - 1, 0) * itemsPerPage
  }&limit=${itemsPerPage}`;

export const fwsUrlFeedItemDelete = (feedItem: FeedItemType): string =>
  `${FWS_BASE_URL}/${feedItemTypeFromEntityType(feedItem.entityType)}/${
    feedItem.uniqueId
  }`;

export const fwsUrlFeedItem = ({
  feedItemId,
}: {
  feedItemId: string;
}): string => `${FWS_BASE_URL}/feeditems/${feedItemId}`;
